/*!
 KeyTable 2.6.2
 ©2009-2021 SpryMedia Ltd - datatables.net/license
*/
(function(e){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(j){return e(j,window,document)}):"object"===typeof exports?module.exports=function(j,k){j||(j=window);if(!k||!k.fn.dataTable)k=require("datatables.net")(j,k).$;return e(k,j,j.document)}:e(jQuery,window,document)})(function(e,j,k,n){var l=e.fn.dataTable,o=0,p=0,m=function(a,b){if(!l.versionCheck||!l.versionCheck("1.10.8"))throw"KeyTable requires DataTables 1.10.8 or newer";this.c=e.extend(!0,{},l.defaults.keyTable,
m.defaults,b);this.s={dt:new l.Api(a),enable:!0,focusDraw:!1,waitingForDraw:!1,lastFocus:null,namespace:".keyTable-"+o++,tabInput:null};this.dom={};var d=this.s.dt.settings()[0],c=d.keytable;if(c)return c;d.keytable=this;this._constructor()};e.extend(m.prototype,{blur:function(){this._blur()},enable:function(a){this.s.enable=a},enabled:function(){return this.s.enable},focus:function(a,b){this._focus(this.s.dt.cell(a,b))},focused:function(a){if(!this.s.lastFocus)return!1;var b=this.s.lastFocus.cell.index();
return a.row===b.row&&a.column===b.column},_constructor:function(){this._tabInput();var a=this,b=this.s.dt,d=e(b.table().node()),c=this.s.namespace,f=!1;"static"===d.css("position")&&d.css("position","relative");e(b.table().body()).on("click"+c,"th, td",function(c){if(!1!==a.s.enable){var d=b.cell(this);d.any()&&a._focus(d,null,!1,c)}});e(k).on("keydown"+c,function(b){f||a._key(b)});if(this.c.blurable)e(k).on("mousedown"+c,function(c){e(c.target).parents(".dataTables_filter").length&&a._blur();e(c.target).parents().filter(b.table().container()).length||
e(c.target).parents("div.DTE").length||!e(c.target).parents("div.editor-datetime").length&&!e(c.target).parents("div.dt-datetime").length&&(e(c.target).parents().filter(".DTFC_Cloned").length||a._blur())});if(this.c.editor){var i=this.c.editor;i.on("open.keyTableMain",function(b,d){"inline"!==d&&a.s.enable&&(a.enable(!1),i.one("close"+c,function(){a.enable(!0)}))});if(this.c.editOnFocus)b.on("key-focus"+c+" key-refocus"+c,function(b,c,d,f){a._editor(null,f,!0)});b.on("key"+c,function(b,c,d,f,g){a._editor(d,
g,!1)});e(b.table().body()).on("dblclick"+c,"th, td",function(c){!1!==a.s.enable&&b.cell(this).any()&&(a.s.lastFocus&&this!==a.s.lastFocus.cell.node()||a._editor(null,c,!0))});i.on("preSubmit",function(){f=!0}).on("preSubmitCancelled",function(){f=!1}).on("submitComplete",function(){f=!1})}if(b.settings()[0].oFeatures.bStateSave)b.on("stateSaveParams"+c,function(b,c,d){d.keyTable=a.s.lastFocus?a.s.lastFocus.cell.index():null});b.on("column-visibility"+c,function(){a._tabInput()});b.on("draw"+c,function(c){a._tabInput();
if(!a.s.focusDraw&&a.s.lastFocus){var d=a.s.lastFocus.relative,f=b.page.info(),g=d.row+f.start;0!==f.recordsDisplay&&(g>=f.recordsDisplay&&(g=f.recordsDisplay-1),a._focus(g,d.column,!0,c))}});this.c.clipboard&&this._clipboard();b.on("destroy"+c,function(){a._blur(!0);b.off(c);e(b.table().body()).off("click"+c,"th, td").off("dblclick"+c,"th, td");e(k).off("mousedown"+c).off("keydown"+c).off("copy"+c).off("paste"+c)});var g=b.state.loaded();if(g&&g.keyTable)b.one("init",function(){var a=b.cell(g.keyTable);
a.any()&&a.focus()});else this.c.focus&&b.cell(this.c.focus).focus()},_blur:function(a){if(this.s.enable&&this.s.lastFocus){var b=this.s.lastFocus.cell;e(b.node()).removeClass(this.c.className);this.s.lastFocus=null;a||(this._updateFixedColumns(b.index().column),this._emitEvent("key-blur",[this.s.dt,b]))}},_clipboard:function(){var a=this.s.dt,b=this,d=this.s.namespace;j.getSelection&&(e(k).on("copy"+d,function(a){var a=a.originalEvent,d=j.getSelection().toString(),e=b.s.lastFocus;!d&&e&&(a.clipboardData.setData("text/plain",
e.cell.render(b.c.clipboardOrthogonal)),a.preventDefault())}),e(k).on("paste"+d,function(c){var c=c.originalEvent,d=b.s.lastFocus,e=k.activeElement,g=b.c.editor,h;if(d&&(!e||"body"===e.nodeName.toLowerCase()))c.preventDefault(),j.clipboardData&&j.clipboardData.getData?h=j.clipboardData.getData("Text"):c.clipboardData&&c.clipboardData.getData&&(h=c.clipboardData.getData("text/plain")),g?g.inline(d.cell.index()).set(g.displayed()[0],h).submit():(d.cell.data(h),a.draw(!1))}))},_columns:function(){var a=
this.s.dt,b=a.columns(this.c.columns).indexes(),d=[];a.columns(":visible").every(function(a){-1!==b.indexOf(a)&&d.push(a)});return d},_editor:function(a,b,d){if(this.s.lastFocus&&!(b&&"draw"===b.type)){var c=this,f=this.s.dt,i=this.c.editor,g=this.s.lastFocus.cell,h=this.s.namespace+"e"+p++;if(!e("div.DTE",g.node()).length&&!(null!==a&&(0<=a&&9>=a||11===a||12===a||14<=a&&31>=a||112<=a&&123>=a||127<=a&&159>=a))){b&&(b.stopPropagation(),13===a&&b.preventDefault());var j=function(){i.one("open"+h,function(){i.off("cancelOpen"+
h);d||e("div.DTE_Field_InputControl input, div.DTE_Field_InputControl textarea").select();f.keys.enable(d?"tab-only":"navigation-only");f.on("key-blur.editor",function(a,b,c){i.displayed()&&c.node()===g.node()&&i.submit()});d&&e(f.table().container()).addClass("dtk-focus-alt");i.on("preSubmitCancelled"+h,function(){setTimeout(function(){c._focus(g,null,false)},50)});i.on("submitUnsuccessful"+h,function(){c._focus(g,null,false)});i.one("close"+h,function(){f.keys.enable(true);f.off("key-blur.editor");
i.off(h);e(f.table().container()).removeClass("dtk-focus-alt");if(c.s.returnSubmit){c.s.returnSubmit=false;c._emitEvent("key-return-submit",[f,g])}})}).one("cancelOpen"+h,function(){i.off(h)}).inline(g.index())};13===a?(d=!0,e(k).one("keyup",function(){j()})):j()}}},_emitEvent:function(a,b){this.s.dt.iterator("table",function(d){e(d.nTable).triggerHandler(a,b)})},_focus:function(a,b,d,c){var f=this,i=this.s.dt,g=i.page.info(),h=this.s.lastFocus;c||(c=null);if(this.s.enable){if("number"!==typeof a){if(!a.any())return;
var l=a.index(),b=l.column,a=i.rows({filter:"applied",order:"applied"}).indexes().indexOf(l.row);if(0>a)return;g.serverSide&&(a+=g.start)}if(-1!==g.length&&(a<g.start||a>=g.start+g.length))this.s.focusDraw=!0,this.s.waitingForDraw=!0,i.one("draw",function(){f.s.focusDraw=!1;f.s.waitingForDraw=!1;f._focus(a,b,n,c)}).page(Math.floor(a/g.length)).draw(!1);else if(-1!==e.inArray(b,this._columns())){g.serverSide&&(a-=g.start);g=i.cells(null,b,{search:"applied",order:"applied"}).flatten();g=i.cell(g[a]);
if(h){if(h.node===g.node()){this._emitEvent("key-refocus",[this.s.dt,g,c||null]);return}this._blur()}this._removeOtherFocus();h=e(g.node());h.addClass(this.c.className);this._updateFixedColumns(b);if(d===n||!0===d)this._scroll(e(j),e(k.body),h,"offset"),d=i.table().body().parentNode,d!==i.table().header().parentNode&&(d=e(d.parentNode),this._scroll(d,d,h,"position"));this.s.lastFocus={cell:g,node:g.node(),relative:{row:i.rows({page:"current"}).indexes().indexOf(g.index().row),column:g.index().column}};
this._emitEvent("key-focus",[this.s.dt,g,c||null]);i.state.save()}}},_key:function(a){if(this.s.waitingForDraw)a.preventDefault();else{var b=this.s.enable;this.s.returnSubmit=("navigation-only"===b||"tab-only"===b)&&13===a.keyCode?!0:!1;var d=!0===b||"navigation-only"===b;if(b&&(!(0===a.keyCode||a.ctrlKey||a.metaKey||a.altKey)||a.ctrlKey&&a.altKey)){var c=this.s.lastFocus;if(c)if(this.s.dt.cell(c.node).any()){var c=this.s.dt,f=this.s.dt.settings()[0].oScroll.sY?!0:!1;if(!(this.c.keys&&-1===e.inArray(a.keyCode,
this.c.keys)))switch(a.keyCode){case 9:this._shift(a,a.shiftKey?"left":"right",!0);break;case 27:this.c.blurable&&!0===b&&this._blur();break;case 33:case 34:d&&!f&&(a.preventDefault(),c.page(33===a.keyCode?"previous":"next").draw(!1));break;case 35:case 36:d&&(a.preventDefault(),b=c.cells({page:"current"}).indexes(),d=this._columns(),this._focus(c.cell(b[35===a.keyCode?b.length-1:d[0]]),null,!0,a));break;case 37:d&&this._shift(a,"left");break;case 38:d&&this._shift(a,"up");break;case 39:d&&this._shift(a,
"right");break;case 40:d&&this._shift(a,"down");break;case 113:if(this.c.editor){this._editor(null,a,!0);break}default:!0===b&&this._emitEvent("key",[c,a.keyCode,this.s.lastFocus.cell,a])}}else this.s.lastFocus=null}}},_removeOtherFocus:function(){var a=this.s.dt.table().node();e.fn.dataTable.tables({api:!0}).iterator("table",function(){this.table().node()!==a&&this.cell.blur()})},_scroll:function(a,b,d,c){var f=d[c](),e=d.outerHeight(),g=d.outerWidth(),h=b.scrollTop(),j=b.scrollLeft(),k=a.height(),
a=a.width();"position"===c&&(f.top+=parseInt(d.closest("table").css("top"),10));f.top<h&&b.scrollTop(f.top);f.left<j&&b.scrollLeft(f.left);f.top+e>h+k&&e<k&&b.scrollTop(f.top+e-k);f.left+g>j+a&&g<a&&b.scrollLeft(f.left+g-a)},_shift:function(a,b,d){var c=this.s.dt,f=c.page.info(),i=f.recordsDisplay,g=this._columns(),h=this.s.lastFocus;if(h){var j=h.cell;j&&((h=c.rows({filter:"applied",order:"applied"}).indexes().indexOf(j.index().row),f.serverSide&&(h+=f.start),c=c.columns(g).indexes().indexOf(j.index().column),
f=h,h=g[c],"right"===b?c>=g.length-1?(f++,h=g[0]):h=g[c+1]:"left"===b?0===c?(f--,h=g[g.length-1]):h=g[c-1]:"up"===b?f--:"down"===b&&f++,0<=f&&f<i&&-1!==e.inArray(h,g))?(a&&a.preventDefault(),this._focus(f,h,!0,a)):!d||!this.c.blurable?a&&a.preventDefault():this._blur())}},_tabInput:function(){var a=this,b=this.s.dt,d=null!==this.c.tabIndex?this.c.tabIndex:b.settings()[0].iTabIndex;-1!=d&&(this.s.tabInput||(d=e('<div><input type="text" tabindex="'+d+'"/></div>').css({position:"absolute",height:1,width:0,
overflow:"hidden"}),d.children().on("focus",function(c){var d=b.cell(":eq(0)",a._columns(),{page:"current"});d.any()&&a._focus(d,null,!0,c)}),this.s.tabInput=d),(d=this.s.dt.cell(":eq(0)","0:visible",{page:"current",order:"current"}).node())&&e(d).prepend(this.s.tabInput))},_updateFixedColumns:function(a){var b=this.s.dt,d=b.settings()[0];if(d._oFixedColumns){var c=d.aoColumns.length-d._oFixedColumns.s.iRightColumns;(a<d._oFixedColumns.s.iLeftColumns||a>=c)&&b.fixedColumns().update()}}});m.defaults=
{blurable:!0,className:"focus",clipboard:!0,clipboardOrthogonal:"display",columns:"",editor:null,editOnFocus:!1,focus:null,keys:null,tabIndex:null};m.version="2.6.2";e.fn.dataTable.KeyTable=m;e.fn.DataTable.KeyTable=m;l.Api.register("cell.blur()",function(){return this.iterator("table",function(a){a.keytable&&a.keytable.blur()})});l.Api.register("cell().focus()",function(){return this.iterator("cell",function(a,b,d){a.keytable&&a.keytable.focus(b,d)})});l.Api.register("keys.disable()",function(){return this.iterator("table",
function(a){a.keytable&&a.keytable.enable(!1)})});l.Api.register("keys.enable()",function(a){return this.iterator("table",function(b){b.keytable&&b.keytable.enable(a===n?!0:a)})});l.Api.register("keys.enabled()",function(){var a=this.context;return a.length?a[0].keytable?a[0].keytable.enabled():!1:!1});l.Api.register("keys.move()",function(a){return this.iterator("table",function(b){b.keytable&&b.keytable._shift(null,a,!1)})});l.ext.selector.cell.push(function(a,b,d){var b=b.focused,a=a.keytable,
c=[];if(!a||b===n)return d;for(var f=0,e=d.length;f<e;f++)(!0===b&&a.focused(d[f])||!1===b&&!a.focused(d[f]))&&c.push(d[f]);return c});e(k).on("preInit.dt.dtk",function(a,b){if("dt"===a.namespace){var d=b.oInit.keys,c=l.defaults.keys;if(d||c)c=e.extend({},c,d),!1!==d&&new m(b,c)}});return m});
